import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format, startOfMonth, subMonths } from 'date-fns';
import { useCallback, useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import useQueryParams from '~/hooks/useQueryParams';
import * as productsQuery from '~/utils/_GetAllProducts.gql';

import DebtListResults from './DebtListResults';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 350,
    minWidth: 175,
    marginTop: 10,
  },
  autoComplete: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  productId: '',
  month: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd').slice(
    0,
    -3,
  ),
};

export default function DebtList() {
  const classes = useStyles();

  const { state, clear, handleChange } = useQueryParams(initialValues);

  const [error] = useState({ message: '' });

  const [productSearch, setProductSearch] = useState('');
  const refetchProduct = useCallback(search => {
    setProductSearch(search);
    //refetch();
  }, []);

  const {
    loading,
    error: productError,
    data,
  } = useQuery(productsQuery, {
    variables: { filter: { search: productSearch } },
  });

  const productList = data?.getAllProducts || [];

  const urlParams = new URLSearchParams();

  if (state.month) {
    urlParams.set('month', state.month);
  }

  if (error.message) {
    return <ErrorMessage error={error} />;
  }
  if (productError) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Prenskuld
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Välj ett månadsintervall för att sedan generera csv-filen,
            uppmärksamma på att det kan ta ett par minuter för att skapa filen.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Månad"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.month}
            onChange={e => handleChange('month', e.target.value)}
          />

          <Autocomplete
            className={classes.autoComplete}
            noOptionsText={loading ? 'Laddar in produkter...' : 'Inga träffar.'}
            name="products"
            filterSelectedOptions
            options={productList}
            getOptionLabel={option => option.name}
            onChange={(_, value) =>
              handleChange('productId', value ? value.id : '')
            }
            onInputChange={async (_, value) => {
              refetchProduct(value);
            }}
            renderInput={params => (
              <TextField {...params} label="Sök produkt" variant="outlined" />
            )}
          />
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clear()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export_debt?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Prenskuld
          </Button>
        </Box>
      </Box>
      <DebtListResults state={state} />
    </>
  );
}
