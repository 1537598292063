import {
  AccountBalance,
  Alarm,
  Assessment,
  AttachMoney,
  BarChart,
  CancelPresentation,
  FormatListBulleted,
  GavelOutlined,
  LocalOffer,
  Money,
  People,
  Public,
  Refresh,
  ReportProblem,
  ShoppingCart,
  SupervisorAccount,
  Timeline,
} from '@material-ui/icons';

const menus = {
  primary: [
    {
      text: 'Användare',
      path: '/users',
      icon: People,
    },
    {
      text: 'Ordrar',
      path: '/order',
      icon: ShoppingCart,
    },
    {
      text: 'Produkter',
      path: '/products',
      icon: People,
    },
    {
      text: 'Prenumerationer ',
      path: '/subscriptions',
      icon: Refresh,
    },
    {
      text: 'Kampanjer',
      path: '/campaigns',
      icon: Alarm,
    },
    {
      text: 'Utgåvor',
      path: '/editions',
      icon: LocalOffer,
    },
  ],
  secondary: [
    {
      text: 'Rapporter',
      icon: Assessment,
    },
    {
      text: 'Statistik',
      path: '/statistics',
      icon: BarChart,
    },
    {
      text: 'Attestering',
      path: '/attests',
      icon: SupervisorAccount,
    },
    {
      text: 'Ärenden',
      path: '/issues',
      icon: FormatListBulleted,
    },
    {
      text: 'Allmänna villkor',
      path: '/terms',
      icon: GavelOutlined,
    },
  ],
  nestedReports: [
    {
      text: 'Försäljning',
      path: '/sales',
      icon: AttachMoney,
    },
    {
      text: 'Moss',
      path: '/moss',
      icon: Public,
    },
    { text: 'Inbetalningar', path: '/income', icon: Money },
    { text: 'Avvikelser', path: '/deviations', icon: ReportProblem },
    { text: 'Prenskuld', path: '/debt', icon: AccountBalance },

    {
      text: 'Nyckeltal',
      path: '/keyfigures',
      icon: Timeline,
    },
    {
      text: 'Uppsägningar',
      path: '/unsubscribe-reasons',
      icon: CancelPresentation,
    },
  ],
};

export default menus;
