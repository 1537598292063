import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, GetApp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import Time from '~/components/Time';
import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import { editionType } from '~/propTypes';

import GenerateDistributionsMutation from './_GenerateDistributions.gql';
import GetDistributionFilters from './_GetDistributionFilters.gql';
import GetEdition from './_GetEdition.gql';
import DistributionListResult from './DistributionListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 150,
    minWidth: 150,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  hasError: false,
  distributionDate: null,
};

export default function DistributionList() {
  const apollo = useApolloClient();
  const { edition } = useParams();
  const { state, params, handleChange } = useQueryParams(initialValues);

  const { error, loading, data } = useQuery(GetEdition, {
    variables: { edition },
  });
  const { data: filterData } = useQuery(GetDistributionFilters, {
    variables: { edition },
  });

  const { isAdmin } = useAuthUser();
  const classes = useStyles();

  const [renderList, { loading: mutationLoading }] = useMutation(
    GenerateDistributionsMutation,
    {
      variables: { edition },
      onCompleted: async () => {
        // Ensure all lists are refreshed
        await apollo.reFetchObservableQueries();
      },
      refetchQueries: ['GetDistributions'],
    },
  );
  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }
  if (mutationLoading) {
    return <p>Genererar distributionslista...</p>;
  }

  const options = filterData?.getDistributionFilters?.distributionDates || [];

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Distributionslista
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            För Banprogrammen genereras distributionslistan manuellt och
            därefter kan man ladda ner CSV-filen. För Einars och Vinternumret så
            kan man generera filen för var dag. Sen väljer man uttagsdatum i
            dropdownen för att sedan ladda ner csv-filen för den dagen. Filen
            baseras då på inkomna ordrar sedan senast uttagsdatum.
          </Typography>
        </CardContent>
      </Card>
      <Box mb={2} className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.hasError}
                onChange={e => handleChange('hasError', e.target.checked)}
              />
            }
            label="Har felmeddelande"
          />
          {isAdmin &&
            ['MC-EIN', 'MC-TVI'].includes(data.getEdition.sku) &&
            options.length > 0 && (
              <TextField
                className={classes.textField}
                select
                label="Uttagsdatum"
                value={state.distributionDate}
                variant="outlined"
                onChange={e => handleChange('distributionDate', e.target.value)}
              >
                <MenuItem value="all">
                  <em>Alla</em>
                </MenuItem>
                {options.map(option => (
                  <MenuItem key={option} value={option}>
                    <Time date={new Date(option)} />
                  </MenuItem>
                ))}
              </TextField>
            )}
        </Box>
        {isAdmin && data.getEdition.sku !== 'MC-TRT' && (
          <Box ml="auto" maxWidth="30%">
            <Button
              color="primary"
              variant="contained"
              startIcon={<Add />}
              onClick={() => renderList()}
            >
              Generera distributionslista
            </Button>
          </Box>
        )}
      </Box>
      <Box>
        <DistributionListResult edition={edition} params={params} />
      </Box>
      {isAdmin && data.getEdition.sku !== 'MC-TRT' && (
        <Box mt={2} display="flex">
          <Box ml="auto">
            <Button
              color="primary"
              variant="contained"
              startIcon={<GetApp />}
              component="a"
              target="_blank"
              href={`/api/export_distribution_list?edition=${edition}&distributionDate=${
                state.distributionDate ? state.distributionDate : ''
              }`}
              rel="noreferrer"
            >
              Ladda ner CSV
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

DistributionList.propTypes = {
  editionData: PropTypes.shape({
    getEdition: editionType,
  }).isRequired,
};
