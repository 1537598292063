import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format, startOfMonth } from 'date-fns';
import { useCallback, useState } from 'react';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import useQueryParams from '~/hooks/useQueryParams';
import * as productsQuery from '~/utils/_GetAllProducts.gql';
import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import SalesListResults from './SalesListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  autoComplete: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  type: 'site',
  dateType: 'daily',
  productId: '',
  site: 'all',
  from: format(startOfMonth(new Date(), 1), 'yyyy-MM-dd'),
  to: '',
};

export default function SalesList() {
  const classes = useStyles();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }

  const siteOptions = toOptions(Types.sites);
  const dateTypeOptions = toOptions(Types.dateTypes, false);

  const [productSearch, setProductSearch] = useState('');
  const refetchProduct = useCallback(search => {
    setProductSearch(search);
    //refetch();
  }, []);

  const { loading, error, data } = useQuery(productsQuery, {
    variables: { filter: { search: productSearch } },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const productList = data?.getAllProducts || [];

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Försäljningsrapport
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.productTextField}
            label="Typ"
            variant="outlined"
            select
            value={state.type}
            onChange={e => handleChange('type', e.target.value)}
          >
            <MenuItem value="site">
              <em>Sajter</em>
            </MenuItem>
            <MenuItem value="product">
              <em>Produkter</em>
            </MenuItem>
          </TextField>
          <TextField
            className={classes.productTextField}
            label="Varumärke"
            variant="outlined"
            select
            value={state.site}
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value={'all'}>
              <em>Alla</em>
            </MenuItem>
            {siteOptions.map(site => (
              <MenuItem key={site.value} value={site.value}>
                {site.text}
              </MenuItem>
            ))}
          </TextField>
          {state.type === 'product' && (
            <Autocomplete
              className={classes.autoComplete}
              noOptionsText={
                loading ? 'Laddar in produkter...' : 'Inga träffar.'
              }
              name="products"
              filterSelectedOptions
              options={productList}
              getOptionLabel={option => option.name}
              onChange={(_, value) =>
                handleChange('productId', value ? value.id : '')
              }
              onInputChange={async (_, value) => {
                refetchProduct(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Sök produkt" variant="outlined" />
              )}
            />
          )}
          <TextField
            className={classes.productTextField}
            label="Datumtyp"
            variant="outlined"
            select
            value={state.dateType}
            onChange={e => handleChange('dateType', e.target.value)}
          >
            {dateTypeOptions.map(dateType => (
              <MenuItem key={dateType.value} value={dateType.value}>
                {dateType.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export_sales?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Försäljningsrapport
          </Button>
        </Box>
      </Box>
      <SalesListResults params={params} />
    </>
  );
}
