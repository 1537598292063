import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { format, startOfMonth, subMonths } from 'date-fns';
import { useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 350,
    minWidth: 175,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  from: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM'),
  to: new Date().toISOString().split('T')[0].slice(0, -3),
};

export default function MossList() {
  const classes = useStyles();

  const { state, clear, handleChange } = useQueryParams(initialValues);

  const [error] = useState({ message: '' });

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }

  if (error.message) {
    return <ErrorMessage error={error} />;
  }
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Mossrapport
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Välj ett månadsintervall för att sedan generera csv-filen. Observera
            att det kan ta ett par minuter att skapa filen.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box ml="auto">
        <Button
          color="primary"
          variant="contained"
          startIcon={<GetApp />}
          component="a"
          target="_blank"
          href={`/api/export_moss?${urlParams.toString()}`}
          rel="noreferrer"
        >
          Mossrapport
        </Button>
      </Box>
    </>
  );
}
