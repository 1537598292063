import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { formatMoney } from '@trmediaab/zebra-utils';
import fetch from 'node-fetch';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';
import useAuthUser from '~/hooks/useAuthUser';
import { productPropType } from '~/propTypes';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ProductDetail({ productData, refetch }) {
  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const [error, setError] = useState({ message: '' });
  const [isFilePicked, setIsFilePicked] = useState(false);

  const {
    id,
    name,
    type,
    sku,
    price,
    vat,
    site,
    products,
    betType,
    allowedPaymentMethods,
    paymentInterval,
    punches,
    costCenter,
    distributable,
    project,
    pdfUrl,
  } = productData.getProduct;

  const handleFileInputChange = async e => {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    await fetch(`/api/upload_product_pdf/${id}`, {
      method: 'POST',
      body: data,
    }).then(async res => {
      if (!res.ok) {
        setError({ message: 'Uppladdning av PDF misslyckades.' });
      }
      setIsFilePicked(true);
      //Re-fetch getProduct query
      if (!pdfUrl) {
        refetch();
      }
    });
  };

  const handleRemoveFile = async () => {
    await fetch(`/api/remove_product_pdf/${id}`, {
      method: 'GET',
    }).then(async res => {
      if (!res.ok) {
        setError({ message: 'Borttagning av PDF misslyckades.' });
      }
      //Re-fetch getProduct query
      refetch();
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsFilePicked(false);
  };

  const tableRows = [
    ['Namn', name],
    ['Produkttyp', Types.productTypes[type]],
    ['SKU', sku],
    ['Pris', formatMoney(price)],
    ['Moms', `${vat}%`],
    ['Sajt', Types.sites[site]],
    [
      'Betalningsmetoder',
      allowedPaymentMethods
        .map(paymentMethod => Types.paymentMethods[paymentMethod])
        .join(', '),
    ],
    [
      'Distribueringsbar (KRAV för Billecta)',
      <FormControlLabel
        key={sku}
        control={<Checkbox checked={distributable ?? false} />}
      />,
    ],
  ];

  if (type === 'subscription') {
    tableRows.push([
      'Betalningsinterval',
      Types.paymentIntervals[paymentInterval],
    ]);

    products.length > 0 &&
      tableRows.push([
        'Produkter',
        products.map(product => product.name).join(', '),
      ]);
  }

  if (type === 'bettip' || type === 'ticket') {
    tableRows.push(['Spelformer', betType.join(', ')]);
    if (type === 'ticket') {
      tableRows.push(['Klipp', punches]);
    }
  }

  if (
    allowedPaymentMethods.some(paymentMethod =>
      ['autogiro', 'notification'].includes(paymentMethod),
    )
  ) {
    tableRows.push(['Kostnadsställe', costCenter], ['Projekt', project]);
  }

  if (error.message) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        {isAdmin && (
          <Box mt={2} display="flex">
            <Box ml={2}>
              <Button
                component={NavLink}
                to={`${pathname}/edit`}
                variant="contained"
                color="secondary"
              >
                Redigera
              </Button>
            </Box>
            <Box ml="auto">
              <Button variant="contained" color="secondary" component="label">
                Ladda upp PDF
                <input type="file" hidden onChange={handleFileInputChange} />
              </Button>
            </Box>
          </Box>
        )}
        {type === 'subscription' && (
          <Box display="flex" mt={2}>
            <Box ml="auto">
              {(isFilePicked || pdfUrl) && (
                <Button
                  onClick={handleRemoveFile}
                  variant="contained"
                  css={({ theme }) => ({
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.text.main,
                    '&:hover': {
                      background: theme.palette.error.dark,
                    },
                  })}
                >
                  Ta bort Pdf
                </Button>
              )}
            </Box>
            <Box ml={2}>
              <Link
                href={pdfUrl}
                target={pdfUrl ? '_blank' : undefined}
                underline="none"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!pdfUrl}
                >
                  Visa Pdf
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Grid>
      <Snackbar
        open={isFilePicked}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Pdf uppladdad!
        </Alert>
      </Snackbar>
    </Grid>
  );
}

ProductDetail.propTypes = {
  productData: PropTypes.shape({
    getProduct: productPropType,
  }).isRequired,
  refetch: PropTypes.func,
};
