import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Types } from 'trhub-utils';

import Select from '~/components/Select';

import query from './_GetActiveCampaigns.gql';

export default function PaywallCampaigns() {
  const { data } = useQuery(query);

  const campaigns = data?.campaigns || [];
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      {Object.entries(Types.sites).map(([site, label]) => (
        <Box key={site} pt={1}>
          <Select
            label={label}
            value={values.paywallCampaigns?.[site] || ''}
            onChange={e => {
              setFieldValue(`paywallCampaigns.${site}`, e.target.value);
            }}
            options={[
              { value: '', label: 'Ingen' },
              ...campaigns
                .filter(c => c.site === site)
                .map(campaign => ({
                  value: campaign.id,
                  label: campaign.name,
                })),
            ]}
          />
        </Box>
      ))}
    </>
  );
}
