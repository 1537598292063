import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { issuePropType } from '~/propTypes';

import mutation from './_RemoveIssue.gql';

export default function IssueDetail({ issueData }) {
  const { pathname } = useLocation();
  const apollo = useApolloClient();
  const history = useHistory();

  const [removeError, setRemoveError] = useState('');

  const {
    id,
    dispatcher,
    category,
    description,
    severity,
    status,
    created,
    assignee,
    items,
  } = issueData.getIssue;

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const handleRemoveIssue = async () => {
    const response = await action({
      variables: {
        id,
      },
    }).catch(responseError => setRemoveError(responseError));

    if (response) {
      history.push(`/issues`);
    }
  };

  if (removeError) {
    return <ErrorMessage error={removeError} />;
  }

  const tableRows = [
    ['Avsändare', dispatcher],
    ['Kategori', Types.issueCategories[category]],
    ['Beskrivning', description],
    ['Allvarsgrad', Types.issueSeverities[severity]],
    ['Status', Types.issueStatus[status]],
    [
      'Skapad',
      <Time key={created} date={new Date(created)} format="yyyy-MM-dd HH:mm" />,
    ],
    ['Mottagare', assignee],
  ];

  if (items?.length > 0) {
    items.forEach((item, index) =>
      tableRows.push([`Logg (${index + 1})`, item]),
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>

        <Box mt={2} display="flex">
          <Box>
            <Button
              component={NavLink}
              to={`${pathname}/edit`}
              variant="contained"
              color="secondary"
            >
              Redigera
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              onClick={handleRemoveIssue}
              variant="contained"
              css={({ theme }) => ({
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.main,
                '&:hover': {
                  background: theme.palette.error.dark,
                },
              })}
            >
              Ta bort
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

IssueDetail.propTypes = {
  issueData: PropTypes.shape({
    getIssue: issuePropType,
  }).isRequired,
};
