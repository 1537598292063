import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';
import query from '~/utils/_GetPayments.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function DeviationsListResults({ params }) {
  const { from, to, source } = params;

  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    page: page,
    limit: pageSize,
    variables: {
      filter: {
        from,
        to,
        deviation: true,
        refund: false,
        source,
      },
    },
  });

  const res = data?.list;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Avvikelsedatum', 'Belopp', 'Order'];

  return (
    <>
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h2">
              Avvikelser (Totalt för perioden: {formatMoney(res.totalAmount)})
            </Typography>
          </CardContent>
          <TableList
            page={page}
            pageSize={pageSize}
            data={res}
            headers={headers}
            updatePage={updatePage}
            updateRowSize={updateRowSize}
          >
            {res?.items.length > 0 ? (
              res.items.map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.tableCellDate} width="30%">
                    <Time date={new Date(item.date)} />
                  </TableCell>
                  <TableCell width="30%">{formatMoney(item.amount)}</TableCell>
                  <TableCell>
                    {item.order ? (
                      <Link
                        component={RouterLink}
                        to={`/order/${item.order.id}`}
                      >
                        {item.order.id}
                      </Link>
                    ) : (
                      'Order saknas'
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  Inga betalningsavvikelser finns för denna filtrering. Prova
                  att ange ett annat datumintervall.
                </TableCell>
              </TableRow>
            )}
          </TableList>
        </Card>
      </Box>
    </>
  );
}

DeviationsListResults.propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    source: PropTypes.string,
  }),
};
