import { useQuery } from '@apollo/client';
import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';
import query from '~/utils/_GetPayments.gql';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderPayments({ order }) {
  const { error, loading, data } = useQuery(query, {
    variables: {
      filter: {
        order: order.id,
        refund: false,
      },
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const res = data?.list;

  return (
    <TablePaper title="Inbetalningar">
      {res.items.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Belopp</TableCell>
                <TableCell>Källa</TableCell>
                <TableCell>Typ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {res.items.map(
                item =>
                  item && (
                    <StyledTableRow key={item.id}>
                      <TableCell>
                        <Time date={new Date(item.date)} />
                      </TableCell>
                      <TableCell>{formatMoney(item.amount)}</TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          color="primary"
                          label={
                            item.source.charAt(0).toUpperCase() +
                            item.source.slice(1)
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        {item.deviation ? 'Avvikelse' : 'Inbetalning'}
                      </TableCell>
                    </StyledTableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoUserData text="Den här ordern saknar inbetalningar" />
      )}
    </TablePaper>
  );
}

OrderPayments.propTypes = {
  order: orderPropType,
};
