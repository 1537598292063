import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import NoUserData from '~/components/NoUserData';
import TablePaper from '~/components/TablePaper';
import { orderPropType } from '~/propTypes';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderLineItems({ order }) {
  const { lineItems, netPrice, totalVAT, totalPrice } = order;
  return (
    <TablePaper title="Varor">
      {lineItems.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vara</TableCell>
                <TableCell>Antal</TableCell>
                <TableCell>Á pris</TableCell>
                <TableCell>Moms</TableCell>
                <TableCell>Rabatt</TableCell>
                <TableCell>Summa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lineItems.map(
                (lineItem, index) =>
                  lineItem && (
                    <StyledTableRow key={index}>
                      <TableCell component="th" scope="row">
                        {lineItem.product ? (
                          <Link
                            component={RouterLink}
                            to={`/products/${lineItem.product.id}`}
                          >
                            {lineItem.product?.name}{' '}
                          </Link>
                        ) : (
                          Types.lineItemTypes[lineItem.type]
                        )}
                        {lineItem.label !== lineItem.product?.name
                          ? lineItem.label
                          : ''}
                        {lineItem.subscription ? (
                          <Link
                            component={RouterLink}
                            to={`/users/${lineItem.subscription.user.id}`}
                          >
                            {lineItem.subscription.user.name}{' '}
                          </Link>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{lineItem.quantity || '1'}</TableCell>
                      <TableCell>{formatMoney(lineItem.price)}</TableCell>
                      <TableCell>{lineItem.vat}%</TableCell>
                      <TableCell>
                        {lineItem.discount
                          ? formatMoney(lineItem.discount)
                          : ''}
                      </TableCell>
                      <TableCell>
                        {formatMoney(
                          (lineItem.price - (lineItem.discount || 0)) *
                            (lineItem.quantity || 1),
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ),
              )}
              <TableRow>
                <TableCell
                  rowSpan={3}
                  css={`
                    border: 0;
                  `}
                />
                <TableCell colSpan={4}>
                  <Box fontWeight="fontWeightBold">Nettobelopp</Box>
                </TableCell>
                <TableCell>{formatMoney(netPrice)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>
                  <Box fontWeight="fontWeightBold">Moms</Box>
                </TableCell>
                <TableCell>{formatMoney(totalVAT)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>
                  <Box fontWeight="fontWeightBold">Totalt</Box>
                </TableCell>
                <TableCell fontWeight="fontWeightBold">
                  {formatMoney(totalPrice)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoUserData text="Den här ordern saknar varor" />
      )}
    </TablePaper>
  );
}

OrderLineItems.propTypes = {
  order: orderPropType,
};
