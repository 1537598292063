import { Box, Button, TextField } from '@material-ui/core';
import { Add, Settings } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import SelectField from '~/components/Select';
import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';

import CampaignListResult from './CampaignListResult';

const initialValues = { search: '', site: 'all', active: 'all' };

export default function CampaignList() {
  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const { state, params, handleChange } = useQueryParams(initialValues);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          display="grid"
          gridAutoFlow="column"
          gridColumnGap="10px"
          css={{
            '.MuiFormControl-root': {
              minWidth: '120px',
            },
          }}
        >
          <TextField
            label="Sök"
            variant="outlined"
            value={state.search}
            onChange={e => handleChange('search', e.target.value)}
          />

          <SelectField
            label="Aktiva"
            value={state.active}
            onChange={e => handleChange('active', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              { value: 'true', label: 'Ja' },
              { value: 'false', label: 'Nej' },
            ]}
          />

          <SelectField
            label="Sajt"
            value={state.site}
            onChange={e => handleChange('site', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              ...Object.entries(Types.sites).map(([value, label]) => ({
                value,
                label,
              })),
            ]}
          />
        </Box>
        <Box gridColumnGap="10px" display="grid" gridAutoFlow="column">
          {isAdmin && (
            <Button
              component={Link}
              to={`${pathname}/settings`}
              color="secondary"
              variant="contained"
              startIcon={<Settings />}
            >
              Inställningar
            </Button>
          )}
          {isAdmin && (
            <Button
              component={Link}
              to={`${pathname}/add`}
              color="primary"
              variant="contained"
              startIcon={<Add />}
            >
              Lägg till
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <CampaignListResult params={params} />
      </Box>
    </>
  );
}
