import {
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import Address from '~/components/Address';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';

export default function OrderInformation({ order }) {
  const {
    id,
    user,
    paymentMethod,
    created,
    orderStatus,
    paymentStatus,
    paidDate,
    address,
    invoiceId,
    drupalId,
    ocr,
  } = order;

  const orderTableRows = [
    ['Ordernummer', id],
    ['Orderdatum', <Time key={user.id} date={new Date(created)} />],
    [
      'Billecta ID',
      invoiceId ? (
        <Link
          href={`https://app.billecta.com/in/View/${invoiceId}`}
          target="_blank"
        >
          {invoiceId}
        </Link>
      ) : (
        ''
      ),
    ],
    ['OCR', ocr],
    [
      'Status',
      <Chip
        key={user.id}
        color="primary"
        label={Types.orderStatus[orderStatus]}
        size="small"
      />,
    ],
    [
      'Betalningsstatus',
      <Chip
        key={user.id}
        color="primary"
        label={
          paymentStatus ? Types.paymentStatus[paymentStatus] : 'Info saknas'
        }
        size="small"
      />,
    ],
    [
      'Betalningsdatum',
      paidDate ? <Time key={user.id} date={new Date(paidDate)} /> : '',
    ],
    [
      'Mottagare',
      <Link key={user.id} component={RouterLink} to={`/users/${user.id}`}>
        {user.name ? user.name : user.email}
      </Link>,
    ],
    ['Mobilnummer', user.mobile || <em>Uppgift saknas</em>],
    ['E-postadress', user.email],
    ['Adress', <Address key={user.id} address={address} />],
  ];

  if (drupalId) {
    orderTableRows.push(['Drupal ID', drupalId]);
  }

  if (paymentMethod) {
    orderTableRows.push([
      'Betalningsmetod',
      <Chip
        key={user.id}
        color="primary"
        label={Types.paymentMethods[paymentMethod]}
        size="small"
      />,
    ]);
  }

  return (
    <TablePaper title="Order">
      <TableContainer>
        <Table>
          <TableBody>
            {orderTableRows.map(([label, value]) => (
              <TableRow key={label}>
                <TableCell
                  css={`
                    vertical-align: top;
                  `}
                >
                  {label}
                </TableCell>
                <TableCell
                  css={`
                    vertical-align: top;
                  `}
                  align="right"
                >
                  {value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TablePaper>
  );
}

OrderInformation.propTypes = {
  order: orderPropType,
};
