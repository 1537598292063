import { useQuery } from '@apollo/client';
import { Box, Button, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import SelectField from '~/components/Select';
import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import GetSubscriptionsProducts from './_GetSubscriptionProducts.gql';
import SubscriptionListResult from './SubscriptionListResult';

const initialValues = {
  active: '',
  paymentMethod: '',
  site: '',
  user: '',
  dueDate: '',
  notEnded: '',
  product: '',
};

export default function SubscriptionList() {
  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const { params, state, handleChange, clear } = useQueryParams(initialValues);
  const { data } = useQuery(GetSubscriptionsProducts);

  return (
    <>
      <Box
        maxWidth="100%"
        display="flex"
        flexWrap="wrap"
        gridAutoFlow="column"
        gridColumnGap="10px"
        gridRowGap="10px"
        alignItems="center"
        mb={2}
        css={{
          '.MuiFormControl-root': {
            minWidth: '120px',
          },
        }}
      >
        <SelectField
          label="Aktiva"
          value={state.active}
          onChange={e => handleChange('active', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            { value: 'true', label: 'Ja' },
            { value: 'false', label: 'Nej' },
          ]}
        />

        <SelectField
          label="Tillsvidare"
          value={state.notEnded}
          onChange={e => handleChange('notEnded', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            { value: 'true', label: 'Ja' },
            { value: 'false', label: 'Nej' },
          ]}
        />

        <SelectField
          label="Betalmetod"
          value={state.paymentMethod}
          onChange={e => handleChange('paymentMethod', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...Object.entries(Types.paymentMethods).map(([value, label]) => ({
              label,
              value,
            })),
          ]}
        />

        <TextField
          label="Användare"
          variant="outlined"
          value={state.user}
          onChange={e => handleChange('user', e.target.value)}
        />

        <TextField
          label="Förfallodatum"
          variant="outlined"
          value={state.dueDate}
          onChange={e => handleChange('dueDate', e.target.value)}
          type="date"
        />

        <SelectField
          label="Sajt"
          value={state.site}
          onChange={e => handleChange('site', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...Object.entries(Types.sites).map(([value, label]) => ({
              value,
              label,
            })),
          ]}
        />

        <SelectField
          label="Produkt"
          value={state.product}
          onChange={e => handleChange('product', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...(data
              ? data.items.map(item => ({ value: item.id, label: item.name }))
              : []),
          ]}
        />

        {!shallowEqual(initialValues, state) && (
          <Button
            color="primary"
            css={({ theme }) => ({
              margin: theme.spacing(1),
              textTransform: 'none',
            })}
            onClick={clear}
          >
            <Box fontWeight="fontWeightBold">Nollställ filter</Box>
          </Button>
        )}

        {isAdmin && (
          <Box>
            <Button
              component={Link}
              to={`${pathname}/add`}
              color="primary"
              variant="contained"
              startIcon={<Add />}
              size="medium"
            >
              Lägg till
            </Button>
          </Box>
        )}
      </Box>
      <Box>
        <SubscriptionListResult params={params} />
      </Box>
    </>
  );
}
