import { useQuery } from '@apollo/client';
import {
  Card,
  CardContent,
  CircularProgress,
  Link,
  Typography,
} from '@material-ui/core';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import DataGrid from '~/components/DataGrid';
import ErrorMessage from '~/components/ErrorMessage';
import Time from '~/components/Time';
import query from '~/utils/_GetPayments.gql';

import * as totalAmountQuery from './_GetPaymentsAmount.gql';

const columns = [
  {
    field: 'order',
    flex: 2,
    headerName: 'Order',
    sortable: false,
    renderCell: params => (
      <>
        {params.value ? (
          <Link component={RouterLink} to={`/order/${params.value.id}`}>
            {params.value.id}
          </Link>
        ) : (
          <>Order saknas (ohanterad inbetalning)</>
        )}
      </>
    ),
  },
  {
    field: 'source',
    flex: 1,
    headerName: 'Källa',
    sortable: false,
    renderCell: params => (
      <>{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</>
    ),
  },
  {
    field: 'date',
    flex: 1,
    headerName: 'Datum',
    renderCell: params => <Time date={new Date(params.value)} />,
  },
  {
    field: 'amount',
    flex: 1,
    headerName: 'Belopp',
    valueFormatter: params => formatMoney(params.value),
  },
];

export default function IncomeListResults({ params }) {
  const { from, to, source } = params;

  const { loading, error, data } = useQuery(totalAmountQuery, {
    variables: {
      filter: {
        from,
        to,
        source,
      },
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const res = data?.getPaymentsAmount;

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Totala inbetalningar för perioden: {formatMoney(res.totalAmount)}
          </Typography>
        </CardContent>
      </Card>
      <DataGrid
        columns={columns}
        query={query}
        defaultSort="-date"
        filter={{
          from,
          to,
          source,
        }}
      />
    </>
  );
}

IncomeListResults.propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }).isRequired,
};
