import { useQuery } from '@apollo/client';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { getYear } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import query from './_GetEditionFilters.gql';
import EditionListResult from './EditionListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
  },
});

const initialValues = {
  editionYear: getYear(new Date()),
  sku: 'all',
};

export default function EditionList() {
  const classes = useStyles();

  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  // Get all editions to populate filters
  const { error, data } = useQuery(query);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const options = data?.getEditionFilters?.editionYears || [];

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box display="grid" gridAutoFlow="column" gridColumnGap="10px">
          {options.length > 0 && (
            <TextField
              className={classes.textField}
              label="Utgivningsår"
              variant="outlined"
              value={state.editionYear}
              select
              onChange={e => handleChange('editionYear', e.target.value)}
            >
              <MenuItem value={-1}>
                <em>Alla</em>
              </MenuItem>
              {options.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            className={classes.textField}
            select
            value={state.sku}
            label="Produkt"
            variant="outlined"
            onChange={e => handleChange('sku', e.target.value)}
          >
            <MenuItem value="all">
              <em>Alla</em>
            </MenuItem>
            {Object.entries(Types.distributableProducts).map(
              ([value, text]) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ),
            )}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        {isAdmin && (
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        )}
      </Box>
      <Box>
        <EditionListResult params={params} />
      </Box>
    </>
  );
}
