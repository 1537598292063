import { Avatar, Box, IconButton, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import DataGrid from '~/components/DataGrid';
import Time from '~/components/Time';

import query from './_GetUsers.gql';

const columns = [
  {
    field: 'id',
    flex: 3,
    headerName: 'Användare',
    sortable: false,
    renderCell: params => (
      <Box alignItems="center" display="flex">
        <IconButton
          aria-label="Avatar"
          component={RouterLink}
          to={`/users/${params.row.id}`}
        >
          <Avatar src={params.row.avatarUrl}>{params.row.initials}</Avatar>
        </IconButton>
        <Box ml={2}>
          <Link component={RouterLink} to={`/users/${params.row.id}`}>
            {params.row.label}
          </Link>
        </Box>
      </Box>
    ),
  },
  {
    field: 'email',
    flex: 2,
    headerName: 'Epost',
  },
  {
    field: 'customerNumber',
    flex: 2,
    headerName: 'Kundnummer',
  },
  {
    field: 'created',
    flex: 1,
    headerName: 'Skapad',
    renderCell: params =>
      params.value && <Time date={new Date(params.value)} />,
  },
  {
    field: 'lastAccess',
    flex: 1,
    headerName: 'Senast inloggad',
    renderCell: params =>
      params.value && <Time date={new Date(params.value)} />,
  },
];

export default function UserListResults({
  selectedRows,
  handleSelectRows,
  params,
}) {
  const { role, search, onlyCollectionPayee } = params;
  return (
    <DataGrid
      checkboxSelection
      columns={columns}
      query={query}
      defaultSort="-lastAccess"
      filter={{
        role,
        search: typeof search === 'number' ? search.toString() : search,
        onlyCollectionPayee,
      }}
      selectionModel={selectedRows}
      onSelectionModelChange={rows => {
        handleSelectRows(rows);
      }}
    />
  );
}

UserListResults.propTypes = {
  params: PropTypes.shape({
    role: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    onlyCollectionPayee: PropTypes.bool.isRequired,
  }).isRequired,
  selectedRows: PropTypes.array.isRequired,
  handleSelectRows: PropTypes.func.isRequired,
};
